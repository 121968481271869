// Set site info
// var siteinfo = document.getElementsByName("siteinfo")[0];
// var site_id = siteinfo.getAttribute("data-siteid");
// var lang = siteinfo.getAttribute("data-sitelang");



$(document).ready(function ()
{

	/*
	 *	SUBNAV
	 */
	$('.has-subnav .nav-link').click(function(e){
		e.preventDefault();
		var $this = $(this);

		var was_open = false;
		if ($this.parent().hasClass('is-open')) {
			was_open = true;
		}

		$('.is-open').removeClass('is-open');

		if (was_open) {
			$this.parent().removeClass('is-open');
		} else {
			$this.parent().addClass('is-open');
		}
	});


	/*
	 *	RICH-CONTENT
	 */

	// Lightbox
	$('.lightbox').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true
	});
	$('.lightbox-media').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true,
		helpers : {
			media : {}
		}
	});

	// Youtube/Vimeo thumbs
	$(".rc-video__thumb").not('.lightbox-media').click( startVideo );
	$(".rc-video__overlay").not('.lightbox-media').click( startVideo );

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
		} else {
			if( src.indexOf('?') > -1 )
			{
				iframe.attr('src', src + '&autoplay=1');
			}
			else
			{
				iframe.attr('src', src + '?autoplay=1');
			}
		}

		$('.rc-video__overlay', block.parent()).fadeOut(150);
		$('.rc-video__thumb', block.parent()).fadeOut(150);
	}

	// Contentblocks slider
	$('.rc-article-slider').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});

	// Quote slider
	$('.rc-quote-slider__slick').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});


	$(window).scroll(function() {
		counters();
	});
	counters();




});

$(window).on('load', function ()
{
	// Image slider (options in data-slick)
	$('.rc-image-slider__slick').slick();
});

var a = 0;
function counters()
{
	var oTop = $('[data-counter]').first().offset().top - window.innerHeight;

	if (a == 0 && $(window).scrollTop() > oTop) {
		a = 1;
		$('[data-counter]').each(function() {
			var $this = $(this),
			    countTo = $this.attr('data-counter');

			$({ countNum: $this.text()}).animate({
				countNum: countTo
			},
			{
				duration: 3000,
				easing:'swing',
				step: function() {
				$this.text(Math.floor(this.countNum));
			},
			complete: function() {
			$this.text(this.countNum);
			}
			});
		})
	};
}



// CAPTCHA
var captcha_validated = false;

function onSubmitreCAPTCHA(response)
{
	captcha_validated = true;
	$('.rc-form form').submit();
}

function validateForm(event)
{
	event.preventDefault();
	var form = this;

	var validator = $(form).data('bs.validator');
	validator.validate();

	if (captcha_validated && !validator.hasErrors()) {
		form.submit();
	} else if(!captcha_validated) {
		grecaptcha.reset();
		grecaptcha.execute();
	}
}
